<template>
  <KitsForm ref="kits-form" :is-create-form="true" />
</template>

<script>

import KitsForm from '../components/KitsForm.vue'

export default {
  name: 'KitsCreate',
  components: {
    KitsForm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['kits-form']) {
      this.$refs['kits-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    const initialData = {
      products: [],
      is_active: false,
    }

    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, initialData)
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_KIT_FORM_CLONE`, initialData)
  },

  setup() {
    const MODULE_NAME = 'kits'
    const MODULE_NAME_CLONE = 'cloneData'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },

}
</script>

<style scoped>

</style>
